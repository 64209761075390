import { render, staticRenderFns } from "./editCommodity.vue?vue&type=template&id=8b05f056&scoped=true"
import script from "./editCommodity.vue?vue&type=script&lang=js"
export * from "./editCommodity.vue?vue&type=script&lang=js"
import style0 from "./editCommodity.vue?vue&type=style&index=0&id=8b05f056&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b05f056",
  null
  
)

export default component.exports